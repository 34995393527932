import { FunctionComponent } from "react";
import { useTranslation } from 'react-i18next';

function getLocalizedField(blog: any, field: any, lang: any) {
    const suffix = lang ? `_${lang}` : '';
    if (blog[field + suffix] === '' || blog[field + suffix] === null) {
        return blog[field];
    } else {
        return blog[field + suffix];
    }
}

const Card: FunctionComponent<any> = ({ blog }) => {
    const { i18n } = useTranslation();

    return (
        <div className="hover:opacity-90 transition-opacity duration-300 rounded-[15px] w-[325px] sm:w-[375px] bg-white shadow-md flex flex-col items-start justify-start box-border min-w-[157px] max-w-full text-left text-sm text-secondary-400 font-heading-h3-bold cursor-pointer h-[240px]">
            <div className="relative w-full min-h-[240px] h-full">
                <img
                    className="rounded-[15px] w-full h-full object-cover"
                    loading="lazy"
                    alt=""
                    src={blog?.header_image_thumbnail ? `${blog.header_image_thumbnail}` : "/welcome_img.png"}
                />
                <div className="absolute inset-0 rounded-[15px] bg-black bg-opacity-30 flex items-end justify-start pb-3">
                    <h3 className="text-white text-base font-semibold px-2 text-left">
                        {getLocalizedField(blog, 'title', i18n.language)}
                    </h3>
                </div>
                <div className="absolute top-2 right-2 flex space-x-1">
                    {/* {blog.activities.map((activity: any, index: number) => (
                        <img
                            key={index}
                            className="h-6 w-6"
                            loading="lazy"
                            style={{filter: 'invert(95%) sepia(10%) saturate(5051%) hue-rotate(322deg) brightness(96%) contrast(101%'}}
                            alt={activity.name}
                            src={activity.icon}
                        />
                    ))} */}
                </div>
                <div className="absolute top-2 left-2 flex space-x-1">
                <div className="flex flex-row items-center justify-start gap-[12px] cursor-pointer">
                            <img
                                className="h-5 w-5 rounded-full object-cover"
                                loading="lazy"
                                alt=""
                                src={blog?.author?.profile_picture ? `${blog.author.profile_picture}` : "/SlowTrav_icon.png"}
                            />
                            <div className="relative leading-[24px] font-medium text-slate-100">
                                {blog?.author?.first_name} {blog?.author?.last_name}
                            </div>
                        </div>
                </div>
            </div>
            {/* <div className="w-full p-3 flex flex-col items-start justify-start gap-[8px] overflow-hidden">
                <div className="w-full flex flex-col items-start justify-start">
                    <div className="flex flex-row items-center justify-between py-[5px] px-0 gap-[20px]">
                        <div className="flex flex-row items-center justify-start gap-[12px] cursor-pointer">
                            <img
                                className="h-5 w-5 rounded-full object-cover"
                                loading="lazy"
                                alt=""
                                src={blog?.author?.profile_picture ? `${blog.author.profile_picture}` : "/SlowTrav_icon.png"}
                            />
                            <div className="relative leading-[24px] font-medium">
                                {blog?.author?.first_name} {blog?.author?.last_name}
                            </div>
                        </div>
                        <div className="relative text-xs leading-[24px]">
                            {blog?.date && new Date(blog.date).toLocaleDateString()}
                        </div>
                    </div>
                </div>
                <div className="whitespace-break-spaces w-full relative text-xs leading-[16px] font-text-small text-gray line-clamp-3">
                    {getLocalizedField(blog, 'summary', i18n.language)}
                </div>
            </div> */}
        </div>
    );
};

export default Card;
